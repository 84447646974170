import Party from "components/Party";
import Contact from "components/Contact";
import Footer from "components/Footer";
import Home from "components/Home";
import Milestones from "components/Milestones";
import Portfolio from "components/Portfolio";
import Pricing from "components/Pricing";
import ScrollToTop from "components/ScrollToTop";
import Services from "components/Services";
import Skills from "components/Skills";
import Testimonials from "components/Testimonials";
import Video from "components/Video";
import Workinghours from "components/Workinghours";
import { motion } from "framer-motion";
import React from "react";

import { useEffect, useRef, useState } from "react";
import { AnimatePresence } from "framer-motion";
import Loader from "./components/Loader";
import Pageloader from "components/pageloader/Pageloader";
import Giftcard from "./components/Giftcard";
import BasicModal from "./components/BasicModal";
import Xmas from "components/Xmas";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [loadedNoel, setLoadedNoel] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 10000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setLoadedNoel(true);
    }, 10000);
  }, []);
  return (
    <motion.div initial="hidden" animate="show">
      <AnimatePresence>{loaded ? null : <Loader />}</AnimatePresence>
      {/* {!loaded ? null : 
      <AnimatePresence>{loadedNoel ? null : <BasicModal />}</AnimatePresence>
       } */}

      {/* only popup - no loader */}
      {/* <AnimatePresence>{loadedNoel ? null : <BasicModal />}</AnimatePresence> */}

      <Home />
      <Pricing />
      <Portfolio />
      <Party />
      {/* <Services /> */}

      <Video />
      {/* <Milestones />
      <Testimonials />
      <Skills /> */}

      <Workinghours />
      <Giftcard />
      <Xmas />
      <Contact />
      <Pageloader />

      <Footer />
      <ScrollToTop />
    </motion.div>
  );
}

export default App;
