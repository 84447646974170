import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import ParticlesComponentStar from "./subComponents/ParticlesComponentStar";
import ParticlesComponent from "./subComponents/ParticlesComponent";
import gold1 from "../assets/gold1.jpg";

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  touch-action: none;
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  z-index: 6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #1f263b;
  // background-color: var(--secondary-color);
  // background: url(${gold1})

  // background-image: linear-gradient(180deg,
  //     rgba(212,175,55, 1),
  //     rgba(212,175,55, 1)
  //   ),
  //   url(${gold1});

  color: var(--third-color);

  svg {
    margin-top: 4rem;
    margin-bottom: -5rem;
    width: 10vw;
    height: auto;
    overflow: visible;
    stroke-linejoin: round;
    stroke-linecap: round;
    z-index: 6;
    g {
      path {
        // stroke: #3d007a;
        stroke: white;
      }
    }
  }

  img {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 48em) {
    svg {
      margin-top: rem;
      width: 15vw;
      margin-left: 0rem;
      margin-bottom: 0;
    }
  }
`;

const Text = styled(motion.span)`
  font-size: 3rem;
  color: var(--secondary-color: );
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @media (max-width: 48em) {
    font-size: 1rem;
    margin-left: 1rem;
  }
`;

const TextNotice = styled(motion.span)`
  width: 30vw;
  font-size: 1.5rem;
  background: var(--third-color);
  color: white;
  padding: 1rem;
  border: 2px dashed white;
  text-align: center;
  .color {
    color: red;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Mountains of Christmas", serif;
  }

  .colorOpen {
    color: red;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2.5rem;
    font-family: "Mountains of Christmas", serif;
  }

  @media (max-width: 48em) {
    width: 80vw;
    font-size: 0.95rem;
    margin-left: 0rem;
    .colorOpen {
      font-size: 1.5rem;
    }
  }
`;

const textVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,

    transition: {
      duration: 1,
      yoyo: Infinity, // repeats infinite times
      ease: "easeInOut",
    },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,

    transition: {
      duration: 4,
      ease: "easeInOut",
    },
  },
};

const icon = {
  hidden: {
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)",
  },
  visible: {
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)",
  },
};

const Loader = () => {
  return (
    <Container
      initial={{
        y: 0,
        opacity: 1,
      }}
      exit={{
        y: "100%",
        opacity: 0,
      }}
      transition={{
        duration: 2,
      }}
    >
      {/* <ParticlesComponent theme="dark"/> */}
      {/* <ParticlesComponentStar /> */}
      <ParticlesComponent />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="48px"
        // viewBox="0 0 24 24"
        viewBox="250 100 100 100"
        width="48px"
        fill="none"
      >
        <g>
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 112.8609,76.136 -1.2002,-22.5 -8.5499,0.2999 -8.85,0.9 -9,1.5001 -8.8501,2.7 -9.1499,4.9499 -6.0001,4.9501 -3.3001,5.55 -1.6498,6.5999 -0.3002,7.6501 0.4502,7.0501 1.9499,5.6999 3.6,4.7999 5.6999,5.4 7.0501,3.3001 7.3499,0.9 10.0503,0.1499 9.4498,-0.9 7.8001,-2.3998 10.5001,-3.9002 -17.85,-4.1998 4.7999,-11.4001 -5.6999,1.5001 -6.3,0.5998 -6.6002,-0.5998 -3.1499,-1.8 -1.3501,-2.7003 -0.5998,-3.8999 1.6497,-2.9999 2.8503,-2.8499 3.8999,-2.1002 4.35,-1.3499 3.75,-0.6001 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 128.9109,49.7358 -21.3001,51.9001 22.9501,4.9502 0.3,-3.3001 2.55,-2.85 13.35,1.3499 1.05,4.0501 0.6001,4.65 14.9998,4.5 8.5501,0.4501 -0.6,-3.3001 h -7.9501 l 0.3002,-57.7502 z m 15.8998,16.8001 -7.2,18.15 9.0003,1.5001 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 169.7111,54.9856 v 54.3002 l 28.1999,-3.4501 7.3499,-1.5 7.0501,-2.3999 4.35,-3.7499 0.6001,-3.0002 2.2499,-45 -3.6,-2.85 -3.2998,-1.3501 -5.1001,-0.2999 z m 21.1498,10.9502 -0.5998,23.8499 3.8999,0.15 3.8999,-2.3999 4.2001,-3.45 2.7,-4.8002 0.2999,-5.0998 -1.3498,-4.65 -2.7,-2.7 -2.5501,-1.3501 -4.0498,-0.15 z"
          />

          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 222.6614,53.1102 -2.55,53.1003 17.5501,4.9498 7.35,0.9 12.3001,-0.4498 6.7501,-3.6 4.3498,-5.55 1.65,-10.5001 -0.5998,-10.3501 -4.35,-19.65 -3.1502,-5.2498 -4.0498,-3.7502 z m 18.6001,18.0003 0.4502,23.5499 3.4497,-1.1999 4.2001,-2.1002 2.9999,-2.9999 1.3502,-3.45 v -4.7999 l -2.0999,-4.5 -3.4501,-2.7 -4.5,-1.8 z"
          />

          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 263.6116,46.8102 11.8499,43.2003 -0.4498,19.9499 2.8499,1.05 14.55,-0.5998 0.6001,-12.6003 20.0999,-41.8499 -18.7501,-5.25 -7.0498,16.3499 -3.7502,-17.3999 z"
          />

          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 349.1118,44.4104 -7.5001,-0.7501 -6.45,-0.2999 -6.3,0.5999 -5.6999,2.1001 -4.8002,1.95 -4.1998,3.7499 4.5,2.1 -10.2002,22.35 1.6501,4.0501 2.55,4.3501 3.75,3.2998 3.45,1.5001 5.25,1.5001 3.75,-0.6001 4.9501,-0.9 2.2499,0.6001 0.75,1.8 -2.2501,2.55 -3.6,1.4998 -5.8499,0.9 -11.4,0.6001 1.3501,13.0502 h 20.7 l 5.6999,-1.0503 3.6,-2.5497 4.35,-4.3501 4.2001,-6.9001 0.7501,-5.6999 -0.1502,-8.85 -1.8,-6.4502 -2.7,-4.65 -4.1999,-1.4998 h -4.6502 l -4.3498,1.4998 -6.9001,0.6001 -1.3501,-1.95 0.4501,-3.0001 2.1,-1.8 3.3,-1.05 4.5,-0.4499 11.2499,-0.4501 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 353.6121,44.86 -0.4498,25.3499 2.7,3.9002 1.65,4.5 0.9,10.05 -0.6001,7.4999 -2.55,6.3 -3.75,6.3 20.7,1.3501 0.4499,-18.3002 10.9502,3.9002 -0.3002,14.8499 12.6,-0.2999 h 6.1501 l 1.0499,-34.35 1.9503,-18.75 -19.0503,-4.8002 -2.0999,21.7502 -11.2501,-1.3501 0.1502,-22.7999 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 412.4126,46.9599 -6.7499,30.0002 -0.4501,31.9498 16.95,0.9 v -8.1 h 15.9001 l 0.15,8.1 21.7499,-0.2999 -13.7999,-56.7 z m 17.4002,19.5001 -4.9502,19.9499 9.4502,0.15 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 503.0124,76.0598 1.6501,-20.85 -9.15,-0.75 -9,-0.15 -7.3502,0.4499 -7.7998,2.2501 -5.7002,2.85 -4.0499,4.6499 -7.05,8.5502 9.75,37.8 -3.3001,0.2999 5.1001,2.1002 11.0999,0.1499 6.1501,-0.1499 7.2,-1.5001 6.4499,-2.85 4.0502,-3.6 0.4498,-15.6002 -3.8999,1.9503 -5.1001,1.0499 -5.6999,0.9 -3.9002,-0.1499 -4.9498,-0.7501 -2.5501,-2.7 -1.0499,-3.6 1.5001,-3.9002 3.4497,-3.6 2.8503,-2.3998 5.25,-1.2002 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 510.5123,45.6097 -4.1998,58.5003 21.8999,2.7 1.95,-17.4002 10.2002,18.4502 28.3498,2.6997 -17.85,-27.9 23.1001,-24.0001 -29.7,-5.9998 -13.0502,17.3999 1.1999,-19.3502 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 266.9083,124.5844 0.0751,-6.2249 -1.2751,-3.1501 -1.8748,-1.8749 -17.6252,0.2251 -2.325,0.0748 -6.7499,2.85 -5.1752,2.925 -3.1499,3.225 -1.7251,2.2502 -0.7501,3.075 0.3751,4.0498 1.8751,4.0502 2.6249,2.7 4.2752,2.9251 5.25,1.9499 8.25,0.7501 8.325,-0.0752 9.7501,-1.1999 0.0748,-9.9751 -2.325,0.15 -2.7,1.5752 -3.8999,0.5998 -4.35,0.0751 -3.075,-0.4501 -3.4501,-1.3499 -2.175,-2.2501 -0.375,-1.95 0.375,-2.7 2.325,-2.6249 3.45,-1.8 3.6,-0.6001 4.65,0.15 2.2501,0.2999 3.4501,1.5752 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 275.4587,113.5596 v 29.7 h 18.75 v -29.7 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 300.3588,113.2597 h 40.0501 v 8.3999 h -10.5001 v 21.45 h -18.2999 v -21.45 h -11.2501 z"
          />
          <motion.path
            variants={pathVariants}
            initial="hidden"
            animate="visible"
            d="m 343.1832,112.9601 17.1,16.425 0.0751,13.9499 18.8249,-0.0748 -0.0749,-14.1753 16.0501,-16.575 h -19.6501 l -5.8501,8.7001 -6.5251,-8.1 z"
          />
        </g>
      </svg>
      <Text variants={textVariants} initial="hidden" animate="visible">
        Welcome to Christchurch minigolf
        <br />
      </Text>

      <TextNotice>
        {/* <p>Monday 24 April opening hours:</p> 10AM - 5 PM
        <hr style={{margin: "0px"}}/> */}
        {/* <p>Canterbury Anniversary Day opening hours: </p>10.30AM - 5.30PM */}
        {/* <p><span className="colorOpen">WE ARE OPEN</span>  </p>
       
        <br/>
        <p><span className="color">Waitangi Day</span> opening hours:  </p>
        <br/>
        <p>6th Feb: 10AM - 5PM.</p> */}
        {/* <p>6th Jan: <span className="color">Close.</span></p> */}

        {/* <p><span className="color">School holidays</span> opening hours: </p> */}
        {/* <p>2nd Jan:  10AM - 6PM.</p> */}

        {/* <p><span className="color">Easter </span> opening hours:  </p>
        <br/>
        <p>Friday 29th March: 10AM - 6PM</p>
        <p>Monday 1st April: 10Am-6PM</p> */}
        {/* <p>6th Jan: <span className="color">Close.</span></p> */}

        {/* <p><span className="color">Anzac day</span> opening hours:  </p>
        <br/>
        <p>Thurday 25th Apr: 10AM - 6PM</p> */}
        {/* <p><span className="color">King's birthday</span> opening hours:  </p>
        <br/>
        <p>Monday 3rd Jun: 10AM - 6PM</p> */}
        {/* <p><span className="color">Matariki day</span> opening hours:  </p>
        <br/>
        <p>Fri 28th Jun: 10AM - 6PM</p> */}
        <p>
          <span className="colorOpen">Opening hours:</span>{" "}
        </p>
        <p>Fri 6th Dec: 10AM - 6PM</p>
        <p>Open 7 days 14th December- 31st January</p>
      </TextNotice>
    </Container>
  );
};

export default Loader;
